@font-face {
  font-family: Konexy;
  src: url('./assets/fonts/KonexyPersonalUse-rg3wK.otf') format('opentype');
}

@import '~antd/dist/antd.css';
/* @import '~antd/dist/antd.dark.css'; */
/* .leaflet-tile.leaflet-tile-loaded {
  filter: invert(1);
} */

* {
  font-family: Konnexy;
}

.ant-layout-sider {
  background-color: #fff;
}

.ant-carousel-vertical .slick-dots li {
  background-color: darkgray;
}

.logo {
  max-width: 100%;
  padding: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-form-item {
  margin-bottom: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mapExpanderCSS {
  position: absolute;
  top: 10 px;
  right: 0;
  width: 20px;
  height: 10px;
  border: 3px solid #73ad21;
}

._sidemenu {
  text-align: justify !important;
}

._topmenu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@keyframes _rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: _rotate;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-delay: -1s;
}

.square-background-white {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 33%,
    #d9d9d9 100%
  );
  background-color: #f0f0f0;
  background-size: 4px 4px;
  background-position: -1px, 2px, 1px 1px;
}

.square-background-purple {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 33%,
    hsla(252, 34%, 35%, 0.938) 100%
  );
  background-color: hsla(237, 53%, 17%, 0.959);
  background-size: 4px 4px;
  background-position: -1px, 2px, 1px 1px;
}

.sun {
  color: rgb(252, 218, 25);
}
