.sify-cpul-0 {
  color: hsl(0, 0%, 100%);
}

.sify-cpu-titles {
  font-size: 1rem;
  width: 100%;
  padding: 5px;
  font-weight: 800;
  border-bottom: 3px solid #4298b5;
}

.sify-cpul-years {
  width: 100%;
}

.sify-cpul-years1 {
  font-size: 0.9rem;
  color: hsl(195, 47%, 48%);
  font-weight: bolder;
}
.sify-cpul-years2 {
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: white;
  font-weight: 900;
}

.sify-cpu-divider-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
