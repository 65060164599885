@font-face {
  font-family: Konexy;
  src: url(/static/media/KonexyPersonalUse-rg3wK.b59c38cd.otf) format('opentype');
}
/* @import '~antd/dist/antd.dark.css'; */
/* .leaflet-tile.leaflet-tile-loaded {
  filter: invert(1);
} */

* {
  font-family: Konnexy;
}

.ant-layout-sider {
  background-color: #fff;
}

.ant-carousel-vertical .slick-dots li {
  background-color: darkgray;
}

.logo {
  max-width: 100%;
  padding: 10px;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-form-item {
  margin-bottom: 5px;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.mapExpanderCSS {
  position: absolute;
  top: 10 px;
  right: 0;
  width: 20px;
  height: 10px;
  border: 3px solid #73ad21;
}

._sidemenu {
  text-align: justify !important;
}

._topmenu {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

@-webkit-keyframes _rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes _rotate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spin {
  -webkit-animation-name: _rotate;
          animation-name: _rotate;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.square-background-white {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 33%,
    #d9d9d9 100%
  );
  background-color: #f0f0f0;
  background-size: 4px 4px;
  background-position: -1px, 2px, 1px 1px;
}

.square-background-purple {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 33%,
    hsla(252, 34%, 35%, 0.938) 100%
  );
  background-color: hsla(237, 53%, 17%, 0.959);
  background-size: 4px 4px;
  background-position: -1px, 2px, 1px 1px;
}

.sun {
  color: rgb(252, 218, 25);
}

.vms-box {
  margin-top: 1.5rem;
  font-size: x-large;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  width: 80% !important;
  height: 10rem;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  -webkit-justify-content: center;
          justify-content: center;
  font-weight: bold;
  word-wrap: break-word;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.met-img {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.met-img > img {
  width: 100%;
  height: 100%;
  border-radius: 0.15rem;
  display: block;
}

.met-img-top,
.met-img-bottom {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: snow;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  z-index: 5;
}

.met-location-right {
  margin-left: auto;
  margin-right: 0;
}

.met-img-bottom {
  top: auto;
  bottom: 0;
  left: 0;
  text-align: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  text-transform: uppercase;
}

.met-img-weather {
  font-size: 2.25rem;
  line-height: 1.25rem;
  font-weight: normal;
}

.met-img-weather > div {
  font-size: 0.75rem;
}

.met-img-scard-top {
  font-size: 0.55rem;
}

.met-img-scard-mid {
  font-size: 2.15rem;
  line-height: 2.15rem;
}

.met-img-scard-bottom {
  font-size: x-small;
}

.met-weather-card {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  /* background-color: rgb(20, 20, 20); */
  background-color: rgb(173, 173, 173);
  color: rgb(31, 31, 31);
  margin: 0.5rem 0.25rem;
  height: 100%;
  /* border-radius: 0.5rem; */
  overflow: hidden;
}

.met-weather-card-left,
.met-weather-card-right {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 2.5rem;
  line-height: 110%;
  padding: 1rem 0.5rem;
  position: relative;
}

.met-weather-card-right {
  display: block;
  text-align: center;
  /* background-color: rgb(31, 29, 29); */
  background-color: rgb(206, 206, 206);
}

.met-weather-card-left-text,
.met-weather-card-right-text {
  font-size: 0.9rem;
  color: rgb(31, 31, 31);
  line-height: 110%;
}

.met-weather-card-right {
  display: grid;
  grid-template-columns: 1fr;
}

.met-card-bottom {
  font-size: 0.8rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
}

.ant-card-body {
  padding: 12px 24px;
}
.sify-cpul-0 {
  color: hsl(0, 0%, 100%);
}

.sify-cpu-titles {
  font-size: 1rem;
  width: 100%;
  padding: 5px;
  font-weight: 800;
  border-bottom: 3px solid #4298b5;
}

.sify-cpul-years {
  width: 100%;
}

.sify-cpul-years1 {
  font-size: 0.9rem;
  color: hsl(195, 47%, 48%);
  font-weight: bolder;
}
.sify-cpul-years2 {
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: white;
  font-weight: 900;
}

.sify-cpu-divider-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cpu1 {
  display: grid !important;
  grid-template-columns: 1fr;
}

.cpu1-title {
  font-size: 2rem;
  color: white;
  width: 100%;
  padding: 5px;
  border-bottom: 2px solid #4298b5;
}

.cpu1-years {
  float: left;
  width: 50%;
  padding: 2px;
}

.cpu1-color,
.cpu1-des {
  color: #4298b5;
}

.cpu1-pdes {
  margin: 0.5rem 0;
}

.cpu1-des {
  font-size: 0.9rem;
  font-weight: 900;
}

.cpu1-num {
  font-size: 1.3rem;
  color: white;
  font-weight: 900;
  line-height: 1.3rem;
}

.cpu1-desimg {
  color: rgb(194, 194, 194);
  font-size: 0.7rem;
  font-weight: 800;
}
.cpu1-videoimg {
  width: 100%;
  /* height: 80px; */
}

.cpu1-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.cpu1-bottom-des {
  font-size: 0.9rem;
}


.cpu3 {
  padding: 30px 0px;
}

.cpu3-title {
  font-size: 1.05rem;
  font-weight: 900;
  border-bottom: 3px solid #4298b5;
}

.cpu3 .des {
  color: white;
  width: 100%;
}

.graphy-parent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow-x: hidden;
}

.graphis {
  margin-top: 1rem;
  width: 100%;
  /* float: left; */
  display: block;
  text-align: justify;
}

.graphi {
  padding: 2px;
  /* float: left; */
  text-align: center;
}
.cpu3 .colordes {
  width: 50%;
  padding: 2px;
  float: left;
  height: 82px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}
.cpu3 .coleach {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: left;
          justify-content: left;
  -webkit-align-items: center;
          align-items: center;
  text-transform: uppercase;
}
.cpu3 .colr {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ff6666;
}
.cpu3 .colg {
  width: 12px;
  height: 12px;
  background-color: #cad5e2;
  border-radius: 50%;
}
.cpu3 .colw {
  width: 12px;
  height: 12px;
  background-color: #35bdd0;
  border-radius: 50%;
}
.cpu3 .cold {
  width: 12px;
  height: 12px;
  background-color: #3944f7;
  border-radius: 50%;
}
.cpu3 .cdes {
  font-size: 0.65rem;
  color: white;
  margin-left: 0.1rem;
}
.cpu3 span {
  color: #ffffff !important;
  font-weight: 600;
}

.cpu3-crousel {
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100% !important;
  /* background-color: blueviolet; */
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  /* color: #474747; */
  color: #ffffff;
  font-weight: bold;
  overflow: hidden;
}

@-webkit-keyframes toTop {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@keyframes toTop {
  0% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }

  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
}

@-webkit-keyframes bottomToCurrent {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

@keyframes bottomToCurrent {
  0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

.cpu4 .titles {
  font-size: 0.9rem;
  color: white;
  width: 100%;
  border-bottom: 3px solid #4298b5;
  font-weight: 900;
}

.cpu4-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.cpu4 .years {
  float: left;
  width: 33.3333%;
  padding: 2px;
  margin-top: 10px;
}
.cpu4 .years1 {
  font-size: 0.8rem;
  color: #4298b5;
  font-weight: 900;
  line-height: 0.8rem;
}
.cpu4 .years2 {
  font-size: 0.9rem;
  color: white;
  font-weight: 800;
}

.cpu5 .titles {
  font-size: 0.9rem;
  color: white;
  width: 100%;
  border-bottom: 3px solid #4298b5;
  font-weight: 900;
}

.cpu5-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.cpu5 .years {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin-top: 1rem;
}
.cpu5 .years1 {
  font-size: 0.9rem;
  color: #4298b5;
  font-weight: 900;
  line-height: 0.9rem;
}
.cpu5 .years2 {
  font-size: 0.9rem;
  color: white;
  font-weight: 800;
  line-height: 0.9rem;
}

.cpu5-chart > div > div > canvas {
  /* background-color: rgb(221, 221, 221); */
  width: 100% !important;
  height: 15rem !important;
}

.cpur .titles {
  font-size: 1rem;
  color: white;
  width: 100%;
  padding: 5px;
  text-align: right;
  font-weight: 900;
  border-bottom: 3px solid #4298b5;
}

.cpur-weather {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1rem;
}

.cpur-weather-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0.5rem 0;
}

.cpur-weather-temp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.cpur-weather-temp-now {
  font-size: 2.5rem;
  position: relative;
  line-height: 2.5rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.cpur-weather-temp-now > sup {
  top: 25%;
  position: absolute;
  font-size: 1.5rem;
}

.cpur-weather-temp-feel {
  font-size: small;
}

.cpur-weather-bottom {
  display: grid;
  grid-template-columns: 1fr 0.75fr 1fr;
}

.cpur-weather-lbottom {
  padding-left: 1rem;
}

.cpur-weather-rbottom {
  padding-left: 1.5rem;
}

.cpur-icon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.65rem;
}

.cpur2-vms {
  margin-top: 1rem;
  font-size: x-large;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  width: 100%;
  height: 10rem;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  -webkit-justify-content: center;
          justify-content: center;
  font-weight: bold;
  word-wrap: break-word;
  text-align: center;
}

.cpur3-ecb-sub {
  display: grid;
  grid-template-columns: 1.5fr 0fr 1.5fr;
  padding-left: 0.5rem;
  font-size: 0.9rem;
}

.cpur3-sub-value {
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: white;
  font-weight: 900;
}

.cpur3-sub1,
.cpur3-sub2 {
  font-weight: bolder;
}

.cpur3-sub2 {
  text-align: end;
}

.cpur3-chart > div > div > canvas {
  margin-top: 1rem;
  width: 100% !important;
  height: 7rem !important;
}

.cpur4-vss {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.cpur4-img {
    width: 95%;
    display: block;
    margin: 0.25rem auto ;
    -webkit-filter: blur(1px);
            filter: blur(1px);
}
.maintop {
  background: none;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  /* background-color: rgba(0, 0, 0, 0.377); */
}

.maintop-shadow {
  width: 100%;
  height: 15rem;
  position: absolute;
  top: 0;
  z-index: -1;
  background-image: linear-gradient(to bottom, #141414cc, rgba(0, 0, 0, 0));
}

.title {
  background: none;
  width: 100%;
  font-size: 25px;
  font-weight: 900;
  color: white;
  margin: 0%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bolder;
}

.titleback > img {
  margin: 1.2rem auto;
  width: 100%;
}

.esch {
  width: 100%;
  background: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 900;
}
.view {
  background: none;
  width: 18%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}
.viewtitle {
  width: 100%;
  padding: 5px;
  color: white;
  font-size: 12px;
  font-weight: 900;
}
.nums {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
}
.view > .nums > .num {
  padding: 1px;
  margin: 1px;
  width: 15px;
  height: 18px;
  background-color: hsl(180, 100%, 50%);
  padding: 2px;
  border-radius: 2px;
  font-weight: 900;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
}
.rise {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 2px;
}
.risevulume {
  color: white;
  font-weight: 900;
}
.triangle_down {
  width: 0;
  height: 0;
  background: none;
  border-left: 6px solid transparent;
  border-top: 12px solid yellow;
  border-right: 6px solid transparent;
}
.triangle_up {
  width: 0;
  height: 0;
  background: none;
  border-left: 6px solid transparent;
  border-bottom: 12px solid white;
  border-right: 6px solid transparent;
}

.sify {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
}

.sify > .sify-cpu,
.sify > .sify-map {
  position: absolute;
}

.sify-map {
  width: 100%;
  height: 100vh;
}

.sify-cpu {
  padding: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 30%;
  height: 100vh;
  top: 0;
  background-color: #4e3f7d;
  opacity: 0.9;
  z-index: 499;
  background-image: linear-gradient(to right, #463a76e7, #141642e7);
  overflow: auto;
}

.sify-maintop {
  z-index: 499;
  text-align: center;
  display: block;
  width: 40%;
  left: 50%;
  right: 50%;
  height: 10rem;
}

.sify-cpu::-webkit-scrollbar {
  width: 0px;
}

.sify-cpul {
  left: 0;
}

.sify-cpur {
  right: 0;
  background-image: linear-gradient(to left, #463a76e7, #141642e7);
}

.sify-cpu > div {
  padding: 5px;
  color: hsl(0, 100%, 99%);
  margin: 0.15rem 0;
}

html
  body
  div#root
  section.ant-layout.ant-layout-has-sider.bg
  section.ant-layout.bg
  main.ant-layout-content.bg
  div.sify
  div.sify-map
  div.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom
  div.leaflet-control-container
  div.leaflet-top.leaflet-left
  div.leaflet-control-zoom.leaflet-bar.leaflet-control {
  display: none !important;
}

.dashboard-title-color {
  color: #4298b5;
}

