.met-img {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.met-img > img {
  width: 100%;
  height: 100%;
  border-radius: 0.15rem;
  display: block;
}

.met-img-top,
.met-img-bottom {
  display: flex;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: snow;
  padding: 0.75rem 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  z-index: 5;
}

.met-location-right {
  margin-left: auto;
  margin-right: 0;
}

.met-img-bottom {
  top: auto;
  bottom: 0;
  left: 0;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
}

.met-img-weather {
  font-size: 2.25rem;
  line-height: 1.25rem;
  font-weight: normal;
}

.met-img-weather > div {
  font-size: 0.75rem;
}

.met-img-scard-top {
  font-size: 0.55rem;
}

.met-img-scard-mid {
  font-size: 2.15rem;
  line-height: 2.15rem;
}

.met-img-scard-bottom {
  font-size: x-small;
}

.met-weather-card {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  /* background-color: rgb(20, 20, 20); */
  background-color: rgb(173, 173, 173);
  color: rgb(31, 31, 31);
  margin: 0.5rem 0.25rem;
  height: 100%;
  /* border-radius: 0.5rem; */
  overflow: hidden;
}

.met-weather-card-left,
.met-weather-card-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2.5rem;
  line-height: 110%;
  padding: 1rem 0.5rem;
  position: relative;
}

.met-weather-card-right {
  display: block;
  text-align: center;
  /* background-color: rgb(31, 29, 29); */
  background-color: rgb(206, 206, 206);
}

.met-weather-card-left-text,
.met-weather-card-right-text {
  font-size: 0.9rem;
  color: rgb(31, 31, 31);
  line-height: 110%;
}

.met-weather-card-right {
  display: grid;
  grid-template-columns: 1fr;
}

.met-card-bottom {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-evenly;
}

.ant-card-body {
  padding: 12px 24px;
}