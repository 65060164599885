.cpur3-ecb-sub {
  display: grid;
  grid-template-columns: 1.5fr 0fr 1.5fr;
  padding-left: 0.5rem;
  font-size: 0.9rem;
}

.cpur3-sub-value {
  font-size: 1.1rem;
  line-height: 1.1rem;
  color: white;
  font-weight: 900;
}

.cpur3-sub1,
.cpur3-sub2 {
  font-weight: bolder;
}

.cpur3-sub2 {
  text-align: end;
}

.cpur3-chart > div > div > canvas {
  margin-top: 1rem;
  width: 100% !important;
  height: 7rem !important;
}
