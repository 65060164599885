.cpur4-vss {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.cpur4-img {
    width: 95%;
    display: block;
    margin: 0.25rem auto ;
    filter: blur(1px);
}